import React, { useState, useEffect, useCallback } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import Asset30 from "../images/Asset 30.png";
import Asset28 from "../images/Asset 28.png";
import Asset14 from "../images/Asset 14.png";
import Asset898 from "../images/Asset899.png";

import Asset13 from "../images/Asset 13.png";
import { toast } from "react-toastify";

import { motion } from "framer-motion";
import Modal from "../CategoriesPage/Modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from "react-router-dom";


function MyFavorites() {
  let user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const [getNotifications, setGetNotifications] = useState("");
  const [Loading, setLoading] = useState("");
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [popularData, setPopularData] = useState("");
  const [favoritesCardData, setFavoritesCardData] = useState("");
  const [tempData, setTempData] = useState("");
  const [modal, setModal] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const[menuData,setMenuData]=useState([]);

  const getPopulerItems = useCallback(async () => {
    setLoading(true);
    try {
      const apiResponse = await Promise.all([
        axios.get(
          `https://chakkimart.in/admin/UserApi/getcartlist?user_id=${user_id}`
        ),
        axios.get(
          `https://chakkimart.in/admin/UserApi/getWishlist?user_id=${user_id}`
        ),
      ]);
      // console.log(apiResponse[1].data.message)
      if (apiResponse[1].data.message === "No Item in Wishlist") {
        setNullMessage(true);
        setLoading(false);
      } else {
        const whishLists = apiResponse[1].data.wishlist;
        const cartList = apiResponse[0].data.items;
        //   const populatItemList = apiResponse[0].data.items;
        setFavoritesCardData(whishLists);

        console.log(whishLists, cartList, "apiResponse");
        whishLists.forEach((menuItem, index) => {
          cartList.forEach((cartItem) => {
            if (cartItem && menuItem.menu_id === cartItem.menu_id) {
              whishLists[index].isCartList = true;
              whishLists[index].quantity = cartItem.quantity;
              // console.log( 'res',whishLists[index].quantity = cartItem.quantity)
            }
          });
        });
        setFavoritesCardData(whishLists);
        setLoading(false);
      }

      //   populatItemList.forEach((menuItem, index) => {
      //     cartList.forEach((cartItem) => {
      //       if (cartItem && menuItem.menu_id === cartItem.menu_id) {
      //         populatItemList[index].isCartList = true;
      //         populatItemList[index].quantity = cartItem.quantity;
      //         // localStorage.setItem("service_id", id);
      //       }
      //     });
      //   });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(!user_id){
      navigate('/login');
    }
    else{
      getPopulerItems();
      window.scroll(0,0)
    }
  }, []);

  const discriptionModalData = (item) => {
    const price = item.weightprice?.[0]?.price || item.menu_price; 
    setPopularData({
      title: item.menu_title,
      desc: item.menu_description,
      price: price,
      id: item.menu_id,
      weightprice: item.weightprice,
    });
    setDiscriptionModal(true);
  };


  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch("https://chakkimart.in/admin/UserApi/homescreendata");
        const data = await response.json();
        setMenuData(data.set_menu);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };
    fetchMenuData();
  }, []);

  const cartUpdate = async (id, quantity) => {
    if (quantity < 1) {
      removeFromCart(id);
    } else {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", id);
      formData.append("quantity", quantity);

      const url = "https://chakkimart.in/admin/UserApi/updatecart";
      const config = {
        headers: { "content-type": "multipart/form-data" },
        body: formData,
      };
      setLoading(true);
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setLoading(false);
          window.location.reload(false);

          // alert("Your Profile Update Successfully");
          // props.showAlert("Your Profile Updated Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  //Remove From WhishList
  const removeFromCart = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/removecart";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  //Remove From WhishList
  const removeToWhishList = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/deleteWishlist";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          //   setLikeImage(true);
          // window.location.reload(false);
          toast.success("Item Is Removed from Wishlist", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getPopulerItems();
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  //Modal
  const getModalData = (item) => {
    const price = item.weightprice?.[0]?.price || item.menu_price;
    setTempData({
      image: item.menu_image,
      title: item.menu_title,
      desc: item.menu_description,
      price: price,  
      id: item.menu_id,
      weightprice: item.weightprice,
    });
  
    setModal(true);
  };

  return (
    <div>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />
          <div style={{ backgroundColor: "#f4ede4" }}>
            <div
              className="container p-3"
              initial={{ x: "100vw" }}
              animate={{ x: 0 }}
              transition={{
                delay: 0.2,
                duration: 0.5,
              }}
            >
              <div className="row">
                {nullMessage && (
                  <>
                    <div className="text-center">
                      <img
                        src={Asset898}
                        alt="img"
                        style={{ width: "300px", height: "300px" }}
                      />
                    </div>
                  </>
                )}
                {favoritesCardData &&
                  favoritesCardData.map((item, i) => {
                    return (
                      <>
                        <div className="col-md-3 ">
                          <div class="item">
                            <div class="card popular_card33 mx-auto">
                              <img
                                src={`https://chakkimart.in/admin/${item.menu_image}`}
                                class="popular_img33"
                                alt="img"
                                // onClick={() =>
                                //   discriptionModalData(
                                //     item.menu_title,
                                //     item.menu_description,
                                //     item.menu_price,
                                //     item.menu_id
                                //   )
                                // }
                              />
                              <div class="card-body popular_card_body">
                                <div class="d-flex justify-content-between">
                                  <h6 class="menu_card_heading">
                                    {item.menu_title.slice(0, 15)}
                                  </h6>
                                  {/* <img
                                    src={Asset20}
                                    class="popular_like_product_img"
                                    alt="img"
                                    onClick={() =>
                                      removeToWhishList(item.menu_id)
                                    }
                                  /> */}
                                  <i
                                    class="fa-solid fa-heart likes text-danger"
                                    onClick={() =>
                                      removeToWhishList(item.menu_id)
                                    }
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </div>
                                <p class="menu_disc">
                                  {item.menu_description.slice(0, 25)}...
                                </p>
                                <div class="d-flex justify-content-between">
                                  <p class="menu_price">
                                  Rs. {item.weightprice[0]?.price || item.menu_price}
                                  </p>
                                  {item?.isCartList ? (
                                    <>
                                      <div class="d-flex justify-content-center cart_quantity">
                                        <img
                                          src={Asset30}
                                          class="cart_minus_img sub"
                                          role="button"
                                          onClick={() =>
                                            cartUpdate(
                                              item.menu_id,
                                              Number(item.quantity) - 1
                                            )
                                          }
                                          alt="img"
                                        />
                                        <input
                                          class=" cart_quantity_input count"
                                          id="quantity"
                                          type="text"
                                          value={
                                            item?.isCartList && item.quantity
                                          }
                                        />
                                        <img
                                          src={Asset14}
                                          class="cart_plus_img add"
                                          role="button"
                                          alt="img"
                                          onClick={() =>
                                            cartUpdate(
                                              item.menu_id,
                                              Number(item.quantity) + 1
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={Asset14}
                                        class="popular_add_product_imgss"
                                        alt="img"
                                        onClick={() =>
                                          getModalData(
                                            item
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                  {/* <img
                                        src={Asset14}
                                        class="popular_add_product_img"
                                        alt="img"
                                        onClick={() =>
                                          getModalData(
                                            item.menu_title,
                                            item.menu_description,
                                            item.menu_price,
                                            item.menu_image,
                                            item.menu_id
                                          )
                                        }
                                      /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Popular Card data  */}

          {modal === true ? (
            <Modal
              hide={() => setModal(false)}
              image={tempData.image}
              price={tempData.price}
              title={tempData.title}
              desc={tempData.desc}
              id={tempData.id}
              weightprice={tempData.weightprice}
            />
          ) : (
            ""
          )}
          <Footer />
        </>
      )}
    </div>
  );
}

export default MyFavorites;
