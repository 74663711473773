import React, { useState } from "react";
import Asset30 from "../images/Asset 30.png";
import Asset14 from "../images/Asset 14.png";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";

function CancelReasonModal(props) {
  console.log(props);
  let user_id = localStorage.getItem("user_id");

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  let modalStyle = {
    display: "block",
  };

  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  //For Cnacel reason Modal
  const cancelReason = async () => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("order_id", props.id);
    {
      !otherReason
        ? formData.append("cancel_reason", reason)
        : formData.append("cancel_reason", otherReason);
    }
    // formData.append("cancel_reason", reason);
    //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/cancelorder";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          // window.location.reload(false);
          toast.success("Order is Cancelled Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };
  return (
    <div>
      <div
        class="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-1">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalToggleLabel">
                Why Do You Want To Cancel Your Order
              </h3>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="name"
                  id="I Choose Wrong Delivery Address"
                  value="I Choose Wrong Delivery Address"
                  onChange={() => setReason("I Choose Wrong Delivery Address")}
                />
                <label
                  class="form-check-label"
                  for="I Choose Wrong Delivery Address"
                >
                  I Choose Wrong Delivery Address
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="name"
                  id="I Forget To Apply Coupon"
                  value="I Forget To Apply Coupon"
                  onChange={() => setReason("I Forget To Apply Coupon")}
                />
                <label class="form-check-label" for="I Forget To Apply Coupon">
                  I Forget To Apply Coupon
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="name"
                  id="I Changed My Mind"
                  onChange={() => setReason("I Changed My Mind")}
                />
                <label class="form-check-label" for="I Changed My Mind">
                  I Changed My Mind
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="name"
                  id="Other Reason"
                  onChange={() => setReason("Other Reason")}
                />
                <label class="form-check-label" for="Other Reason">
                  Other Reason
                </label>
                {reason === "Other Reason" && (
                  <>
                    <div className="input-group mt-3">
                      <input
                        type="text"
                        className="form-control login_input"
                        id="login_input"
                        placeholder="Please Enter Your Reason"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                      />
                    </div>
                  </>
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn login_btn"
                    onClick={cancelReason}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelReasonModal;
