import React, { useState, useEffect, useCallback } from "react";
import Carausel from "./Carausel";
import Header from "./Header";
import MenuBar from "./MenuBar";
import Menu from "./MenuItems/Menu";
import PopularItems from "./PopularItems/PopularItems";
import axios from "axios";
import Footer from "../Footer/Footer";
import Category from "./Category/Category";
import Loader from "./Loader/Loader";
import { Suspense } from "react";
import Loader2 from "./Loader/Loader2";

// const Category = React.lazy(()=>import("./Category/Category"))

function Home() {
  const [getBanners, setGetBanners] = useState([]);
  const [getCategory, setGetCategory] = useState([]);

  const [popularItems, setPopularItems] = useState([]);

  const [setMenu, setSetMenu] = useState([]);

  const [setCart, setSetCart] = useState([]);

  const [setWhishlist, setSetWhishlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [heartImage, setHeartImage] = useState("");

  let user_id = localStorage.getItem("user_id");

  const loadHomedata = (event) => {
    let formData = new FormData(); //formdata object

    // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
    let url;
if(!user_id){
   url = `https://chakkimart.in/admin/UserApi/homescreendata`;

}
else{
  url = `https://chakkimart.in/admin/UserApi/homescreendata?user_id=${user_id}`;
}

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response);
        setLoading(false);

        setGetBanners(response.data.banners);
        setGetCategory(response.data.category);
        // setPopularItems(response.data.popular_items);
        // setSetMenu(response.data.set_menu);
        setSetCart(response.data.cart);
        // setSetWhishlist(response.data.wishlist);
        localStorage.setItem("cart", response.data.cart.length);

        const menuItems = response.data.set_menu;
        const popularCategory = response.data.popular_items;

        const whishLists = response.data.wishlist;
        const cartList = response.data.cart;
        // console.log(menuItems, whishLists, cartList, "apiResponse");

        menuItems.forEach((menuItem, index) => {
          whishLists.forEach((wishItem) => {
            if (wishItem && menuItem.menu_id === wishItem.menu_id) {
              menuItems[index].isWishList = true;
            }
          });
        });
        setSetMenu(menuItems);
        setSetWhishlist(whishLists);
        // setPopularItems()
        setLoading(false);

        menuItems.forEach((menuItem, index) => {
          cartList.forEach((cartItem) => {
            if (cartItem && menuItem.menu_id === cartItem.menu_id) {
              menuItems[index].isCartList = true;
              menuItems[index].quantity = cartItem.quantity;
            }
          });
        });

        popularCategory.forEach((menuItem, index) => {
          whishLists.forEach((wishItem) => {
            if (wishItem && menuItem.menu_id === wishItem.menu_id) {
              popularCategory[index].isWishList = true;
            }
          });
        });
        setPopularItems(popularCategory);
        // setSetWhishlist(whishLists);
        // setPopularItems()
        setLoading(false);

        popularCategory.forEach((menuItem, index) => {
          cartList.forEach((cartItem) => {
            if (cartItem && menuItem.menu_id === cartItem.menu_id) {
              popularCategory[index].isCartList = true;
              popularCategory[index].quantity = cartItem.quantity;
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadHomedata();
    // window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {loading ? (
        <>
          <Loader />
          <MenuBar />
          <Header setCart={setCart} />
          <Carausel getBanners={getBanners} />
          <Category getCategory={getCategory} />
          <Menu
            setMenu={setMenu}
            loadHomedata={(value) => {
              loadHomedata(value);
            }}
          />

          <PopularItems
            popularItems={popularItems}
            loadHomedata={(value) => {
              loadHomedata(value);
            }}
          />
        </>
      ) : (
        <>
          <MenuBar />
          <Header setCart={setCart} />
          <Carausel getBanners={getBanners} />
          <Category getCategory={getCategory} />
          <Menu
            setMenu={setMenu}
            loadHomedata={(value) => {
              loadHomedata(value);
            }}
          />

          <PopularItems
            popularItems={popularItems}
            loadHomedata={(value) => {
              loadHomedata(value);
            }}
          />
        </>
      )}

      <Footer />
    </div>
  );
}

export default Home;
