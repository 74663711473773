import React, { useState, useEffect } from "react";
import axios from "axios";
import Asset301 from "../images/Asset 301.png";
import Asset38 from "../images/Asset 38.png";
import Asset40 from "../images/Asset 40.png";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import Footer from "../Footer/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Profile() {
  const user_id = localStorage.getItem("user_id");
  const mobile = localStorage.getItem("mobile_number");
  const useremail = localStorage.getItem("user_email");
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(null);
  const [profileFirstName, setProfileFirstName] = useState("");
  const [profileLastName, setProfileLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState(useremail || "");
  const [getImage, setGetImage] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch profile information on component mount
  const getProfileInfo = async () => {
    try {
      const response = await axios.get(
        `https://chakkimart.in/admin/UserApi/getProfile/?email=${useremail}`
      );
      const profile = response.data.profile;
      setProfileFirstName(profile.user_firstname);
      setProfileLastName(profile.user_lastname);
      setMobileNumber(profile.user_mobile);
      setGetImage(profile.user_profile);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load profile information.");
    }
  };

  useEffect(() => {
    getProfileInfo();
  }, [useremail]);

  const uploadPicture = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const profileUpdate = async (e) => {
    e.preventDefault();
    if (!profileFirstName || !profileLastName || !email) {
      toast.warn("All fields are required!", { autoClose: 3000 });
      return;
    }

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("first_name", profileFirstName);
    formData.append("last_name", profileLastName);
    formData.append("mobile", mobileNumber);
    formData.append("email", email);
    formData.append("profile", profilePicture || getImage);

    localStorage.setItem("user_firstname", profileFirstName);
    localStorage.setItem("user_lastname", profileLastName);
    localStorage.setItem("email", email);
    localStorage.setItem("user_id", user_id);

    console.log('firstname',profileFirstName);
    setLoading(true);
    try {
      await axios.post(
        "https://chakkimart.in/admin/UserApi/updateprofile",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      toast.success("Profile updated successfully!", { autoClose: 3000 });
      navigate("/");
    } catch (error) {
      console.error(error);
      toast.error("Profile update failed.");
    } finally {
      setLoading(false);
    }
  };

  const removeProfilePhoto = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(
        "https://chakkimart.in/admin/UserApi/deleteprofilephoto",
        { mobile },
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success("Profile photo removed.", { autoClose: 3000 });
      setGetImage(null);
    } catch (error) {
      console.error(error);
      toast.error("Failed to remove profile photo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MenuBar />
      <Header />
      <form onSubmit={profileUpdate}>
        <div className="profile_container" style={{ padding: "100px" }}>
          <div className="container inner_part">
            <div className="row">
              <div className="col-md-3 text-center">
                <div className="profile-section">
                  <div className="profile-picture">
                    {getImage || profilePicture ? (
                      <img
                        src={
                          profilePicture
                            ? URL.createObjectURL(profilePicture)
                            : `https://chakkimart.in/admin/${getImage}`
                        }
                        alt="Profile"
                        className="mb-2"
                        style={{ width: "100px", height: "100px" }}
                      />
                    ) : (
                      <img
                        src={Asset301}
                        alt="Default"
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid black",
                        }}
                      />
                    )}
                  </div>
                  <div className="upload-btn-wrapper">
                    <img src={Asset38} alt="Upload" style={{ width: "25px" }} />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={uploadPicture}
                    />
                  </div>
                  {getImage && (
                    <div className="remove-btn-wrapper">
                      <img
                        src={Asset40}
                        alt="Remove"
                        onClick={removeProfilePhoto}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )}
                </div>
              </div>
               <div class="row">
              <div className="col-md-6">
                <label>First Name</label>
                <input
                  type="text"
                  value={profileFirstName}
                  onChange={(e) => setProfileFirstName(e.target.value)}
                  className="form-control profile_input"
                />
              </div>
              <div className="col-md-6">
                <label>Last Name</label>
                <input
                  type="text"
                  value={profileLastName}
                  onChange={(e) => setProfileLastName(e.target.value)}
                  className="form-control profile_input "
                />
              </div>
              </div>
              <div class="row">
              <div className="col-md-6">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control profile_input"
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label>Mobile Number</label>
                <input
                  type="text"
                  value={mobileNumber}
                  className="form-control profile_input"
                  
                />
              </div>
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn login_btn" disabled={loading}>
                  {loading ? "Updating..." : "Update Profile"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default Profile;
