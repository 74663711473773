import React, { useEffect, useState } from "react";
import Asset21 from "../images/Logo.png";
import Asset18 from "../images/Asset 18.png";
import { toast } from "react-toastify";
import axios from "axios";
import OtpPage from "./OtpPage";
import ProfilePage from "./ProfilePage";
import MenuBar from "../Home/MenuBar";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

function UserLogin() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [numberPage, setNumberPage] = useState(true);
  const [otpPage, setOtpPage] = useState(false);
  const [profilePage, setProfilePage] = useState(false);
  const [email,setEmail]=useState("");

  const [loading, setLoading] = useState(false);

  // const onKeyPressEvent2 = (event) => {
  //   const keyCode = event.keyCode || event.which;
  //   const keyValue = String.fromCharCode(keyCode);
  //   if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
  //   return;
  // };

  const login = async (e) => {
    e.preventDefault();
  
    // Email validation check
    if (!email || !email.includes("@")) {
      toast.error("Please Enter a Valid Email", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setNumberPage(true); // Show number/email input page
      setOtpPage(false); // Hide OTP page
      return; // Stop execution if email is invalid
    }
  
    const formData = new FormData();
    formData.append("email", email);
  
    const url = "https://chakkimart.in/admin/UserApi/signup";
  
    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
  
      setLoading(true); // Start loading spinner
  
      const response = await axios.post(url, formData, config);
  
      console.log(response.data);
  
      if (response.data.status) {
        toast.success("OTP Sent Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
  
        setOtpPage(true); // Show OTP page
        setNumberPage(false); // Hide email input page
        setProfilePage(false); // Hide profile page (if applicable)
      } else {
        throw new Error(response.data.message || "Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  

useEffect(()=>{
window.scroll(0,0);
},[])

  return (
    <div>
      <MenuBar/>
      <Header/>
      {numberPage && (
        <>
        <form onSubmit={login}> 
        <div className="login_container" >
            <div className="container">
              <div className="row justify-content-center">
                <div className="card login_card" style={{padding:'30px'}}>
                  <div className="card-img-top">
                    {/* <img src={Asset21} className="login_img" alt="img" /> */}
                    <center>
                    <h4>LOGIN</h4>
                    </center>
                  </div>
                  <div className="card-body lodin_card_body">
                   
                      <label
                        for="login_input"
                        className="form-label login_label"
                      >
                        Enter Email
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="login_mob_img">
                          <img src={Asset18} alt="img" />
                        </span>
                        <input
                          type="text"
                          className="form-control login_input"
                          id="login_input"
                          placeholder="Enter Email Address"
                          // onKeyPress={onKeyPressEvent2}
                          // maxLength="10"
                          // minLength="10"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="text-center">
                        {!loading && (
                          <button type="submit" className="btn login_btn">
                            Send OTP
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn login_btn"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>{" "}
                            Otp Sending...
                          </button>
                        )}
                      </div>
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
          
        </>
      )}
      {otpPage && (
        <>
        <div style={{backgroundColor:'#f4ede4 '}}>
        <OtpPage email={email} />
        </div>
        
        </>
      )}
      {profilePage && (
        <>
        <div style={{backgroundColor:'#f4ede4 '}}>
        <ProfilePage email={email} />
        </div>
        
        </>
      )}
      <Footer/>
    </div>
  );
}

export default UserLogin;
