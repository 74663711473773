import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

function DeleteAddressModal(props) {
  const [loading, setLoading] = useState(false);

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  // console.log(props);
  let modalStyle = {
    display: "block",
  };

  //Delete Address
  const deleteAddress = async (id) => {
    const formData = new FormData();
    // formData.append("user_id", user_id);
    formData.append("address_id", id);

    const url = "https://chakkimart.in/admin/UserApi/deleteAddress";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      body: formData,
    };
    // setLoading(true);
    axios.post(url, formData, config).then((response) => {
      console.log(response);
      // setLoading(false);

      window.location.reload(false);

      // alert("Your Profile Update Successfully");
      // props.showAlert("Your Profile Updated Successfully", "success");
    });
    toast
      .success("Your Address Is Updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });
  };

  return (
    <div>
      <div
        class="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-1">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalToggleLabel">
                Confirmation !!!
              </h3>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h5>Are You Sure You Want to Delete This Address ?</h5>
                  </div>
                </div>
              </div>
              {!loading && (
                <button
                  type="submit"
                  class="btn login_btn mx-3"
                  style={{ backgroundColor: "white" }}
                  onClick={() => deleteAddress(props.address_id)}
                >
                  Yes
                </button>
              )}
              {loading && (
                <button className="btn login_btn" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Deleting Address...
                </button>
              )}

              <button
                type="submit"
                class="btn login_btn"
                style={{ backgroundColor: "white" }}
                onClick={props.hide}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAddressModal;
