import React, { useState, useEffect } from "react";
import Asset30 from "../images/Asset 30.png";
import Asset14 from "../images/Asset 14.png";
import axios from "axios";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Modal(props) {
  const [loading, setLoading] = useState(false);
  const [selectedGrindingType, setSelectedGrindingType] = useState('');
  const [count, setCount] = useState(1);
  const [productData, setProductData] = useState('');
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  let user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const dropIn = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: { duration: 0.1, type: "spring", damping: 25, stiffness: 500 },
    },
    exit: { y: "100vh", opacity: 0 },
  };

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };

  const handleWeightClick = (item) => {
    setSelectedWeight(item);
    setTotalPrice(count * item.price);
  };

  const incrementCount = () => {
    setCount(count + 1);
    setTotalPrice((count + 1) * (selectedWeight ? selectedWeight.price : 0));
  };

  const dec = () => {
    if (count > 1) {
      setCount(count - 1);
      setTotalPrice((count - 1) * (selectedWeight ? selectedWeight.price : 0));
    }
  };

  const handleAddToCart = async () => {
    // Validation for both selectedWeight and selectedGrindingType
    if (!selectedWeight) {
      toast.warn("Please select a package size.", { autoClose: 3000 });
      return;
    }
    if (!selectedGrindingType) {
      toast.warn("Please select a grinding type.", { autoClose: 3000 });
      return;
    }
  
    if (!user_id) {
      navigate('/login');
      return;
    }
  
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", props.id);
    formData.append("quantity", count);
    formData.append("pprice", totalPrice);
    formData.append("type_of_grinding", selectedGrindingType);
  
    // setLoading(true);
  
    try {
      await axios.post("https://chakkimart.in/admin/UserApi/addtocart", formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      
      toast.success("Item Added To Cart Successfully", { 
        autoClose: 3000,
        onClose: () => {
          props.hide();  // Close the modal when the toast closes
          navigate("/"); // Navigate to the home page
        }
      }); 

    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding item to cart. Please try again.");
    } finally {
      // setLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch("https://chakkimart.in/admin/UserApi/homescreendata");
        const dataproduct = await response.json();
        setProductData(dataproduct.data);
      } catch (error) {
        console.error("Error fetching product data", error);
      }
    };

    fetchProductData();
  }, []);

  return (
    <div>
      <div
        className="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-1">
            <div className="modal-header">
              <img
                src={`https://chakkimart.in/admin/${props.image}`}
                alt="img"
                className="modal_image"
              />
              <h3 className="modal-title" id="exampleModalToggleLabel">
                {props.title}
              </h3>
              <button type="button" className="btn-close" onClick={props.hide}></button>
            </div>
            <div className="modal-body">
              <h6 style={{ color: "#000" }}>Package Size</h6>
              <span style={{ color: "#000" }}>Select one option</span>
              <div className="row mb-3">
                {props.weightprice.map((item, index) => (
                  <div
                    key={index}
                    className="col text-center"
                    style={{
                      border: "1px solid #000",
                      backgroundColor: selectedWeight === item ? "#bb9b22" : "rgb(229 221 211 / 34%)",
                      margin: "10px",
                      borderRadius: "7px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleWeightClick(item)}
                  >
                    <div style={{ fontSize: "20px", color: "#000", fontWeight: "600" }}>
                      {item.weight} kg
                    </div>
                    <div style={{ fontSize: "16px", lineHeight: "1.5", color: "#000" }}>
                      ₹{item.price}
                    </div>
                  </div>
                ))}
              </div>

              <h6 style={{ color: "#000" }}>Type of Grinding</h6>
              <span style={{ color: "#000" }}>Select one option</span>
              <div className="input-group" style={{ display: "inline-grid" }}>
                {["Fine", "Normal", "Coarse"].map((type) => (
                  <label className="input-option" style={{ margin: "3px 0" }} key={type}>
                    <input
                      type="radio"
                      value={type}
                      checked={selectedGrindingType === type}
                      onChange={() => setSelectedGrindingType(type)}
                      style={{ borderColor: "gold", borderWidth: "2px", borderStyle: "solid" }}
                    />&nbsp;&nbsp;
                    <span style={{ color: "black" }}>{type}</span>
                  </label>
                ))}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h6>Description</h6>
                  <span style={{ color: "#000" }}>{props.desc}</span>
                  <div className="d-flex justify-content-between">
                    <h2>Rs. {totalPrice}</h2>
                    <div className="text_down">
                      <img
                        src={Asset30}
                        className="cart_minus_img sub"
                        role="button"
                        alt="decrease quantity"
                        onClick={dec}
                      />
                      <input
                        className="cart_quantity_input count"
                        type="text"
                        disabled
                        value={count}
                      />
                      <img
                        src={Asset14}
                        className="cart_plus_img add"
                        role="button"
                        alt="increase quantity"
                        onClick={incrementCount}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-grid gap-2">
                <button
                  className="btn addcart"
                  type="button"
                  onClick={handleAddToCart}
                  disabled={loading || !selectedWeight || !selectedGrindingType}  // Button is disabled unless both options are selected
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Wait...
                    </>
                  ) : (
                    "Add to cart"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
