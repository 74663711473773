import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import Header from "../Header";
import MenuBar from "../MenuBar";
import axios from "axios";
import Asset29 from "../../images/Asset 29.png";
import Asset32 from "../../images/Asset 32.png";
import Asset33 from "../../images/Asset 33.png";
import DeleteAddressModal from "./DeleteAddressModal";
import EditAddressModal from "./EditAddressModal";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function MyAddress() {
  let user_id = localStorage.getItem("user_id");

  const [addressPage, setAddressPage] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [addressType, setAddressType] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [modal, setModal] = useState(false);
  const [tempData, setTempData] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");
  
  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  //Get Address
  const getAddressInfo = () => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `https://chakkimart.in/admin/UserApi/getuserAddress?user_id=${user_id}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setAddressData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAddressInfo();
  }, []);

  //For Address Section
  const addressSectionToggle = () => {
    if (addressPage === false) {
      setAddressPage(true);
    } else {
      setAddressPage(false);
    }
  };

  //Add Address
  const addAddress = async () => {

    const formData = new FormData();
  
     
  
    // Append form data
  
    formData.append("user_id", user_id);
  
    formData.append("address_type", addressType);
  
    formData.append("address", address);
  
    formData.append("address_name", name);
  
    formData.append("city", city);
  
    formData.append("pincode", pincode);
  
    formData.append("country", "India");
  
    formData.append("mobile", mobile);
  
    formData.append("state", state);
  
      const url = "https://chakkimart.in/admin/UserApi/addUserAddress";
  
    const config = {
  
      headers: { "content-type": "multipart/form-data" },
  
      body: formData,
  
    };
  
  
  
    // Show loader
  
    setLoading(true);
  
  
  
    try {
  
      const response = await axios.post(url, formData, config);
  
      console.log(response);
  
      setLoading(false);
  
      getAddressInfo(); 
  
    } catch (error) {
  
      console.error(error);
  
      setLoading(false);
  
    }
  
  };

  //Get Data for Delete
  const getModalData = (address, state, pincode, city, address_id) => {
    let modalData = [address, state, pincode, city, address_id];

    setTempData((item) => [1, ...modalData]);
    return setModal(true);
  };

  //Get Data For Update
  const getEditData = (
    address,
    state,
    pincode,
    city,
    address_id,
    mobile,
    address_name,
    address_type
  ) => {
    let editModalData = [
      address,
      state,
      pincode,
      city,
      address_id,
      mobile,
      address_name,
      address_type,
    ];

    setEditData((item) => [1, ...editModalData]);
    return setEditModal(true);
  };

  return (
    <div>
      <MenuBar />
      <Header />
      <div style={{ backgroundColor: "#f4ede4" }}>
        <div className="container">
          <label
            class="form-label profile_label mt-3"
            style={{
              color: "#7D453D",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            1. Shipping Billing Information
          </label>

          {addressData &&
            addressData.map((item) => {
              //   console.log(item);
              return (
                <>
                  <div
                    class="card mb-2"
                    style={{ backgroundColor: "#F7F4F0", borderRadius: "10px" }}
                  initial={{x:"100vw"}}
                  animate={{x:0}}
                  transition={{delay:0.2}}
                  >
                    <div class="card-body">
                      <h5 class="card-title">{item.address}</h5>

                      <h6 class="card-subtitle mb-2 text-muted">{item.city}</h6>
                      <p class="card-text">{item.state}</p>
                      <p class="card-text">{item.pincode}</p>
                      <a
                        role="button"
                        class="card-link"
                        onClick={() =>
                          getModalData(
                            item.address,
                            item.city,
                            item.state,
                            item.pincode,
                            item.address_id
                          )
                        }
                       
                      >
                        <img
                          src={Asset32}
                          alt="img"
                          style={{ height: "20px" }}
                          whileHover={{scale:1.1}}
                        />
                      </a>
                      <Link
                        role="button"
                        class="card-link"
                        to={`/home/address/edit/${item.address_id}`}
                      >
                        <img
                          src={Asset33}
                          alt="img"
                          style={{ height: "20px" }}
                          whileHover={{scale:1.1}}
                        />
                      </Link>
                    </div>
                  </div>
                </>
              );
            })}

          <hr />
          {modal === true ? (
            <DeleteAddressModal
              hide={() => setModal(false)}
              address_id={tempData[5]}
            />
          ) : (
            ""
          )}

          <div
            class="form-label profile_label"
            style={{
              color: "#7D453D",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            OR
          </div>
          <button
            type="submit"
            className="btn login_btn mb-5 mt-5"
            onClick={addressSectionToggle}
            style={{ backgroundColor: "white" }}
          >
            + Add New Address
          </button>

          {addressPage && (
            <form>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      style={{
                        backgroundColor: "transparent",
                        // border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      onKeyPress={onKeyPressEvent2}
                      maxLength="10"
                      minLength="10"
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                      style={{
                        backgroundColor: "transparent",
                        // border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      style={{
                        backgroundColor: "transparent",
                        // border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      City
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      style={{
                        backgroundColor: "transparent",
                        // border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Address Type
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      onChange={(e) => setAddressType(e.target.value)}
                      value={addressType}
                      placeholder="Home, Office etc."
                      style={{
                        backgroundColor: "transparent",
                        // border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      State
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                    
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Pincode
                    </label>
                    <input
                      type="text"
                      onKeyPress={onKeyPressEvent1}
                      maxLength="6"
                      minLength="6"
                      class="form-control profile_input"
                      value={pincode}
                      onChange={(e) => setPinCode(e.target.value)}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              {!loading && (
                <button
                  type="submit"
                  class="btn login_btn mb-5 mt-5"
                  style={{ backgroundColor: "white" }}
                  onClick={addAddress}
                >
                  Continue
                </button>
              )}
              {loading && (
                <button
                  className="btn login_btn mb-5 mt-5"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Adding Address...
                </button>
              )}
            </form>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MyAddress;
