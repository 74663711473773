import React, { useState, useEffect } from "react";
import Asset14 from "../../images/Asset 14.png";
import Asset20 from "../../images/h1.png";
import Asset12 from "../../images/Asset 12.png";
import Asset13 from "../../images/Asset 13.png";
import Asset30 from "../../images/Asset 30.png";
import { toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import Modal from "../../CategoriesPage/Modal";
import DescriptionModal from "../../CategoriesPage/DescriptionModal";
import axios from "axios";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function PopularItems(props) {
  // $(document).ready(function (){
  //   $('.likes').click(function (){
  //     $(this).toggleClass("text-danger");
  //   });
  // });

  
  let user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const [tempData, setTempData] = useState("");
  const [modal, setModal] = useState(false);
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [popularData, setPopularData] = useState("");

  const[popularmenuData,setpopularMenuData]=useState([]);


  const options = {
    margin: 30,
    responsiveclass: true,
    nav: false,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    // navText: ['<img className="" src= {Asset104}>', '<img className="" src= {Asset105}>'],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 6,
      },
      1000: {
        items: 6,
      },
    },
  };

  const getModalData = (item) => {
    const price = item.weightprice?.[0]?.price || item.menu_price;
    setTempData({
      image: item.menu_image,
      title: item.menu_title,
      desc: item.menu_description,
      price: price,  
      id: item.menu_id,
      weightprice: item.weightprice,
    });
  
    setModal(true);
  };
  
  

  const discriptionModalData = (item) => {
    const price = item.weightprice?.[0]?.price || item.menu_price; 
    setPopularData({
      title: item.menu_title,
      desc: item.menu_description,
      price: price,
      id: item.menu_id,
      weightprice: item.weightprice,
    });
    setDiscriptionModal(true);
};

  //Add to WhishList
  const addToWhishList = async (item) => {
    // setLoading(true);
    if(!user_id){
      navigate('/login')
    }
    else{
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", item.menu_id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    let url = "https://chakkimart.in/admin/UserApi/addWishlist";
    if (item.isWishList) {
      url = "https://chakkimart.in/admin/UserApi/deleteWishlist";
    }
    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const apiResponse = await axios.post(url, formData, config);
      console.log(apiResponse, "result");
      {props.loadHomedata()};

      {
        !item.isWishList
          ? toast.success("Item is Added To Wish list", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            })
          : toast.warning("Item is Removed from Wish list", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

      }
      

      // window.location.reload(false);
    } catch (error) {
      // setLoading(false);
    }
  }
  };

  //Update Cart Items

  const cartUpdate = async (id, quantity) => {
    if (quantity < 1) {
      removeFromCart(id);
    } 
    else {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", id);
      formData.append("quantity", quantity);

      const url = "https://chakkimart.in/admin/UserApi/updatecart";
      const config = {
        headers: { "content-type": "multipart/form-data" },
        body: formData,
      };
      // setLoading(true);
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          // setLoading(false);
          {props.loadHomedata()};

          // window.location.reload(false);

          // alert("Your Profile Update Successfully");
          // props.showAlert("Your Profile Updated Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    }
  };

  //Remove From WhishList
  const removeFromCart = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/removecart";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          // window.location.reload(false);
      {props.loadHomedata()};

        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };


  useEffect(() => {
    const fetchpopularMenuData = async () => {
      try {
        const response = await fetch("https://chakkimart.in/admin/UserApi/homescreendata");
        const data = await response.json();
        setpopularMenuData(data.popular_items);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };
  
    fetchpopularMenuData();
  }, []);
  

  return (
    <div>
      <div className="popular_items_container">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between">
              <h2 className="menu_heading">Popular Items</h2>
              {/* <a href="#" className="menu_link">
                View All
              </a> */}
            </div>
            <OwlCarousel classNameName="owl-theme" {...options}>
              {props.popularItems &&
                props.popularItems.map((item, i) => {
                  return (
                    <>
                      <motion.div
                       key={item.menu_id}
                        className="item"
                        whileHover={{
                          scale: 0.9,
                        }}
                      >
                         <div class="card popular_card33 mx-auto">
                                  <img
                                    src={`https://chakkimart.in/admin/${item.menu_image}`}
                                    class="popular_img33"
                                    alt="img"
                                    onClick={() =>
                                      discriptionModalData(
                                        item
                                      )
                                    }
                                  />

                          <div className="card-body popular_card_body">
                            <div className="d-flex justify-content-between">
                              <h6 className="menu_card_heading" style={{whiteSpace:'nowrap',fontSize:'16px'}}>
                              {item.menu_title.length > 10
                              ? `${item.menu_title.substring(0, 10)}...`
                              : item.menu_title}
                                {/* {item.menu_title.slice(0, 8)} */}
                              </h6>
                              {/* <img
                                src={item?.isWishList ? Asset20 : Asset13}
                                class="popular_like_product_img"
                                alt="img"
                                onClick={() => addToWhishList(item)}
                              /> */}
                              <i
                                class={
                                  item.isWishList
                                    ? "fa-solid fa-heart likes"
                                    : "fa-regular fa-heart"
                                }
                                onClick={() => addToWhishList(item)}
                                style={{fontSize:'24px' ,color:'gold'}}
                              ></i>
                              {/* <img
                                src={Asset20}
                                className="popular_like_product_img"
                             
                                alt="img"
                                onClick={() =>
                                  removeToWhishList(item.menu_id)
                                }
                              /> */}
                            </div>
                            <div className="menu_disc" style={{fontSize:'14px',whiteSpace:'nowrap'}}>
                            {item.menu_description.length > 10
                              ? `${item.menu_description.substring(0, 10)}...`
                              : item.menu_description}
                              {/* {item.menu_description.slice(0, 10)}... */}
                            </div>
                            <div className="d-flex justify-content-between">
                            <div className="menu_price" style={{ fontSize: '12px' }}>
                                Rs. {item.weightprice[0]?.price || item.menu_price}
                              </div>
                              {item?.isCartList ? (
                                <>
                                  <div class="d-flex justify-content-center cart_quantity">
                                    <img
                                      src={Asset30}
                                      class="cart_minus_img sub"
                                      role="button"
                                      onClick={() =>
                                        cartUpdate(
                                          item.menu_id,
                                          Number(item.quantity) - 1
                                        )
                                      }
                                      alt="img"
                                    />
                                    <input
                                      class=" cart_quantity_input count"
                                      id="quantity"
                                      type="text"
                                      value={item?.isCartList && item.quantity}
                                    />
                                    <img
                                      src={Asset14}
                                      class="cart_plus_img add"
                                      role="button"
                                      alt="img"
                                      onClick={() =>
                                        cartUpdate(
                                          item.menu_id,
                                          Number(item.quantity) + 1
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={Asset14}
                                    class="popular_add_product_img"
                                    alt="img"
                                    onClick={() =>
                                      getModalData(
                                        item
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </>
                  );
                })}
            </OwlCarousel>
            {modal === true ? (
              <Modal
                hide={() => setModal(false)}
                image={tempData.image}
                price={tempData.price}
                title={tempData.title}
                desc={tempData.desc}
                id={tempData.id}
                weightprice={tempData.weightprice}
              />
            ) : (
              ""
            )}
            {discriptionModal === true ? (
              <DescriptionModal
                hide={() => setDiscriptionModal(false)}
                price={popularData.price}
                title={popularData.title}
                desc={popularData.desc}
                id={popularData.id}
                weightprice={popularData.weightprice}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularItems;
