import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import Asset58 from "../images/Asset 58.png";

function Header() {
  const [headerData, setHeaderData] = useState(null);
  const navigate = useNavigate();
  const user_firstname = localStorage.getItem("user_name");
  const user_id = localStorage.getItem("user_id");
  const cartLength = localStorage.getItem("cart");
  const user_email=localStorage.getItem("user_email");
  console.log('userid',user_id);

  useEffect(() => {
    fetch("https://chakkimart.in/admin/UserApi/homescreendata")
      .then((response) => response.json())
      .then((data) => {
        setHeaderData(data.headerfooter_data[0]); 
      })
      .catch((error) => console.error("Error fetching header data:", error));
  }, []);

  function Logout() {
    localStorage.clear();
    navigate("/");
    toast.info("You are logged out", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "colored",
    });
  }

  if (!headerData) return 

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <motion.img
              src={`https://chakkimart.in/admin/${headerData.logo}`}
              className="Restaurant_logo"
              whileHover={{ scale: 1.1 }}
              style={{ width: "80px" }}
              alt="Logo"
            />
          </Link>
         &nbsp;&nbsp;&nbsp;
          <div className="d-flex cart">
            <Link
              className="header_txt1 cart1 position-relative"
              to={`/home/${user_id}/mycart`}
              style={{ textDecoration: "none" }}
            >
              <img src={Asset58} className="cart1" alt="Cart" />
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {cartLength > 0 ? cartLength : ""}
              </span>
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" aria-current="page">
                  <div className="header_item">
                    <i className="fa-solid fa-house" style={{ fontSize: "22px" }}></i>
                    <div className="header_txt">Home</div>
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/aboutus" className="nav-link">
                  <div className="header_item">
                    <i className="fa-solid fa-circle-info" style={{ fontSize: "22px" }}></i>
                    <div className="header_txt">About Us</div>
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/contactus" className="nav-link">
                  <div className="header_item">
                    <i className="fa-solid fa-address-book" style={{ fontSize: "22px" }}></i>
                    <div className="header_txt">Contact Us</div>
                  </div>
                </NavLink>
              </li>
            </ul>

            {user_id ? (
              <div className="dropdown User_drop">
                <button
                  className="btn header_user_btn dropdown-toggle"
                  type="button"
                  id="Button1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {user_firstname && user_firstname.slice(0, 12)}
                </button>
                <ul className="dropdown-menu" aria-labelledby="Button1">
                  <li>
                    <Link className="dropdown-item" to={`/home/profile/${user_id}`}>
                      View Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={`/home/address/${user_id}`}>
                      Address
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={Logout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <NavLink to="/login" className="nav-link">
                <div className="header_item">
                  <i className="fa-solid fa-user" style={{ fontSize: "22px", color: "#a8d470" }}></i>
                  <div className="header_txt">Login</div>
                </div>
              </NavLink>
            )}
          </div>

          <div className="d-flex">
            <NavLink to={`/home/${user_id}/mycart`} className="header_txt1 cart2 position-relative">
              <div className="header_item">
                <i className="fa-solid fa-shopping-cart" style={{ fontSize: "22px", color: "#a8d470" }}></i>
                <div className="header_txt">Cart</div>
              </div>
            </NavLink>
          </div>
        </div>
      </nav>
     
    </div>
  );
}

export default Header;
