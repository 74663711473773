import React, { useState, useEffect } from "react";
import Asset19 from "../images/Asset 19.png";
import Asset21 from "../images/Logo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProfilePage from "./ProfilePage";
import { motion } from "framer-motion";

function OtpPage(props) {
  const [otpValue, setOtpValue] = useState("");
  const [otpPage, setOtpPage] = useState(true);
  const [profilePage, setProfilePage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [email, setEmail] = useState(props.email || ""); 
  const navigate = useNavigate();

  // Ensure email is tracked if props change
  useEffect(() => {
    if (props.email) {
      setEmail(props.email);
      console.log('useremail',props.email)
    }
  }, [props.email]);

  const onKeyPressEvent2 = (event) => {
    const keyValue = String.fromCharCode(event.keyCode || event.which);
    if (!/^\d$/.test(keyValue)) event.preventDefault();
  };

  const varifyOtp = async (e) => {
    e.preventDefault();
    if (!otpValue) {
      return toast.error("Please Enter OTP", { position: "top-center", theme: "colored" });
    }
    if (otpValue.length < 4) {
      return toast.error("Please Enter a 4-digit OTP", { position: "top-center", theme: "colored" });
    }

    const formData = new FormData();
    formData.append("email", email); // Use email from state
    formData.append("otp", otpValue);

    const url = "https://chakkimart.in/admin/UserApi/verifyotp";

    try {
      setLoading(true); 
      const response = await axios.post(url, formData, {
        headers: { "content-type": "multipart/form-data" },
      });

      console.log(response.data);

      if (!response.data.status) {
        toast.error("Invalid OTP", { position: "top-center", theme: "colored" });
        return;
      }

      const profile = response.data.profile;

      if (!profile.user_firstname) {
        localStorage.setItem("user_email", profile.user_email );
        localStorage.setItem("user_firstname", profile.user_firstname);
        localStorage.setItem("user_id", profile.user_id);
        localStorage.setItem("mobile_number", profile.user_mobile);
        localStorage.setItem("username", profile.user_firstname);
        setOtpPage(false);
        setProfilePage(true);
        toast.info("Please Fill Following Information", { position: "top-center", theme: "colored" });
      } else {
        localStorage.setItem("mobile_number", profile.user_mobile);
        localStorage.setItem("user_id", profile.user_id);
        localStorage.setItem("user_firstname", profile.user_firstname);
        localStorage.setItem("user_email", profile.user_email);
        toast.success("You are Logged In Successfully", { position: "top-center", theme: "colored" });
        navigate("/");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Something went wrong. Try again.", { position: "top-center", theme: "colored" });
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    const formData = new FormData();
    formData.append("email", email); 
    const url = "https://chakkimart.in/admin/UserApi/resendotp";

    try {
      setResetLoading(true);
      const response = await axios.post(url, formData, {
        headers: { "content-type": "multipart/form-data" },
      });

      console.log(response.data);
      toast.success("OTP has been resent successfully", { position: "top-center", theme: "colored" });
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error("Failed to resend OTP. Try again.", { position: "top-center", theme: "colored" });
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <div>
      {otpPage && (
        <form onSubmit={varifyOtp}>
          <div className="login_container" initial={{ x: "100vw" }} animate={{ x: 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="card login_card" style={{ padding: "30px" }}>
                  <div className="card-body lodin_card_body">
                    <label htmlFor="login_input" className="form-label login_label">Enter OTP</label>
                    <div className="input-group">
                      <span className="input-group-text" id="login_mob_img">
                        <img src={Asset19} alt="img" />
                      </span>
                      <input
                        type="text"
                        className="form-control login_input"
                        id="login_input"
                        placeholder="OTP"
                        onKeyPress={onKeyPressEvent2}
                        maxLength="4"
                        value={otpValue}
                        onChange={(e) => setOtpValue(e.target.value)}
                        style={{ padding: "8px" }}
                      />
                    </div>
                    <div className="text-center">
                      {!loading ? (
                        <button type="submit" className="btn login_btn">Verify OTP</button>
                      ) : (
                        <button className="btn login_btn" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Verifying OTP...
                        </button>
                      )}
                    </div>
                    <div className="text-center">
                      {!resetLoading ? (
                        <button type="button" className="btn login_btn" onClick={resendOtp}>
                          Resend OTP
                        </button>
                      ) : (
                        <button className="btn login_btn" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Resending OTP...
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {profilePage && <ProfilePage email={props.email}
       />}
    </div>
  );
}

export default OtpPage;
