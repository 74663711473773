import React, { useState, useEffect } from "react";
import Asset38 from "../images/Asset 38.png";
import Asset301 from "../images/Asset 301.png";
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';

function ProfilePage(props) {
  let user_id = localStorage.getItem("user_id");

  const [profilePicture, setProfilePicture] = useState("");
  const [profileFirstName, setProfileFirstName] = useState("");
  const [profileLastName, setProfileLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState(props.email); // Corrected initialization
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const uploadPicture = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const getProfileInfo = async () => {
    const url = `https://chakkimart.in/admin/UserApi/getProfile/?email=${props.email}`;
    
    try {
      const response = await axios.get(url);
      const profile = response.data.profile;
      setProfileFirstName(profile.user_firstname);
      setProfileLastName(profile.user_lastname);
      setEmail(profile.user_email);
      setMobileNumber(profile.user_mobile);
      // Handle profile image if necessary
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProfileInfo();
  }, [props.email]); // Run when props.email changes

  const newProfile = async (e) => {
    e.preventDefault();
    
    if (!profileFirstName || !profileLastName || !email) {
      toast.error('All fields are required!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    const url = "https://chakkimart.in/admin/UserApi/updateprofile";
    const formData = new FormData();
    
    formData.append("user_id", user_id);
    formData.append("first_name", profileFirstName); 
    formData.append("last_name", profileLastName);
    formData.append("email", email);
    formData.append("mobile", mobileNumber);

    if (profilePicture) {
      formData.append("profile", profilePicture); 
    }

    setLoading(true);

    try {
      const response = await axios.post(url, formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      
      const updatedProfile = response.data.profile;
      localStorage.setItem("user_name", updatedProfile.user_firstname);
      localStorage.setItem("user_email", updatedProfile.user_email);
      
      toast.success('Profile updated successfully!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      
      navigate("/"); 
    } catch (error) {
      console.error(error);
      toast.error('Failed to update profile. Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {props.email && (
        <form onSubmit={newProfile}>
          <div className="profile_container" animate={{ x: 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
            <div className="container inner_part">
              <div className="row">
                <div className="col-md-3 text-center">
                  <div className="profile_col_1" style={{ marginTop: "-115px" }}>
                    <div className="profile-section">
                      <img
                        src={profilePicture ? URL.createObjectURL(profilePicture) : Asset301}
                        alt="Profile"
                      />
                    </div>
                    <div className="col-md-2 plus_btn" style={{ marginTop: "-40px", marginLeft: "89px" }}>
                      <div className="upload-btn-wrapper">
                        <img src={Asset38} style={{ height: "25px", width: "25px" }} alt="Upload" />
                        <input type="file" name="myfile" accept="image/jpg, image/jpeg" onChange={uploadPicture} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginLeft: "0px" }}>
                  <div className="col-md-6 mb-3">
                    <label className="form-label profile_label">First Name</label>
                    <input
                      type="text"
                      className="form-control profile_input"
                      value={profileFirstName}
                      onChange={(e) => setProfileFirstName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label profile_label">Last Name</label>
                    <input
                      type="text"
                      className="form-control profile_input"
                      value={profileLastName}
                      onChange={(e) => setProfileLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginLeft: "0px" }}>
                  <div className="col-md-6 mb-3">
                    <label className="form-label profile_label">Email</label>
                    <input
                      type="email"
                      className="form-control profile_input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label profile_label">Mobile no.</label>
                    <input
                      type="text"
                      className="form-control profile_input"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      maxLength="10"
                      minLength="10"
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn login_btn" disabled={loading}>
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default ProfilePage;
