import React, { useState } from "react";
import Asset81 from "../images/Asset 81.png";
import axios from "axios";
import Asset82 from "../images/Asset 82.png";
import Asset83 from "../images/Asset 83.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PaymentModal(props) {
  console.log('payment modal', props);
  let user_id = localStorage.getItem("user_id");
  // let username = localStorage.getItem("user_firstname");
  let user_email = localStorage.getItem("user_email");
  console.log('user_email payment',user_email);
  // let mobile = localStorage.getItem("mobile_number");
  // console.log('username',username);
  

  let localStorageData = localStorage.getItem("address_values"),
    retrivedData = JSON.parse(localStorageData);
  let preDiscount = localStorage.getItem("preDiscount");
  let coupon_discount = localStorage.getItem("coupon_discount");
  let delivery_fee = localStorage.getItem("deliveryCharge");
  let delivery_type = localStorage.getItem("delivery_type");
  let item_price = localStorage.getItem("item_price");
  let coupon_code = localStorage.getItem("coupon_code");
  let total_amount = localStorage.getItem("total_amount_pay");

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };

  //Payment On Delivery
  const cashOnDelivery = async () => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("user_email", user_email);
    console.log("cashOnDelivery user_id", user_id);
    console.log("cashOnDelivery user_email", user_email);
    
    
    {
      delivery_type
        ? formData.append("delivery_type", delivery_type)
        : formData.append("delivery_type", "Take Out");
    }
    formData.append("payment_type", "COD");
    {
      delivery_fee
        ? formData.append("delivery_fee", delivery_fee)
        : formData.append("delivery_fee", 0);
    }

    formData.append("items_price", item_price);
    console.log('itemprice',item_price)
    // {
    //   total_amount !== "" || delivery_fee !== 0
    //     ? formData.append("total_amount", Number(total_amount) + 99)
    //     : formData.append("total_amount", Number(total_amount));
    // }

    formData.append("total_amount", total_amount);
    console.log('totoalamount',total_amount);

    formData.append("address", retrivedData[0]);
    formData.append("user_city", retrivedData[4]);
    formData.append("user_pincode", retrivedData[2]);

    {
      coupon_code
        ? formData.append("coupon_code", coupon_code)
        : formData.append("coupon_code", "");
    }

    formData.append("discount", preDiscount);

    {
      coupon_discount
        ? formData.append("coupon_discount", coupon_discount)
        : formData.append("coupon_discount", 0);
    }

    // formData.append("username", username);
    // console.log("username payment1", username);

    const url = "https://chakkimart.in/admin/UserApi/order";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      body: formData,
    };
    setButtonLoading(true);
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        localStorage.removeItem("address_values");
        localStorage.removeItem("coupon_discount");
        localStorage.removeItem("deliveryCharge");
        localStorage.removeItem("coupon_code");
        localStorage.removeItem("total_amount_pay_after_coupon");
        localStorage.removeItem("cart");

        toast.success("Your Order is Placed Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // window.location.reload(false);
        navigate(`/home/${user_id}/mycart/thanku`);
        setButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
      });
  };

  //Payment On Delivery
  const payByRazorPay = (e) => {
    e.preventDefault();
  
    // if (!username) {
    //   alert("Username is missing. Please try again.");
    //   return;
    // }
  
    const options = {
      key: "rzp_live_PuD99yIxWP004F",
      key_secret: "v1WDq9TMmyt1VOZRV5ImIXT4",
      amount: total_amount * 100,
      currency: "INR",
      name: "Hotel Apsara",
      description: "Online Payment Gateway",
  
      handler: function (response) {
        console.log(response);
  
        if (response.razorpay_payment_id) {
          cashOnDelivery();  
          navigate(`/home/${user_id}/mycart/thanku`);
        }
      },
  
      prefill: {
        // username: username ,  
        // contact: mobile,
        email: user_email ,
      },
      notes: {
        address: retrivedData[0],
      },
      theme: {
        color: "#3399cc",
      },
    };
  
    const pay = new window.Razorpay(options);
    pay.open();
  
    pay.on("payment.failed", function (resp) {
      alert(`Payment Failed: ${resp.error.code}`);
    });
  };
  

  return (
    <div>
      <div class="modal" show fade style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-1">
            <div class="modal-header modal-header55 mt-3">
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body text-center">
              <img
                src={Asset81}
                alt="img"
                style={{ width: "80px", height: "80px" }}
              />
              <p style={{ fontSize: "12px" }} className="mt-2">
                Select Payment Method
              </p>
              <div className="d-flex justify-content-center">
                {/* <div>
                  {" "}
                  <button
                    type="submit"
                    class="btn login_btn "
                    style={{ backgroundColor: "white" }}
                    onClick={payByRazorPay}
                  >
                    <img
                      src={Asset82}
                      alt="img"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />
                    Pay Now
                  </button>
                </div> */}
                <div>
                  {" "}
                  {!loading && (
                    <button
                      type="submit"
                      class="btn login_btn "
                      style={{ backgroundColor: "white" }}
                      onClick={() => cashOnDelivery()}
                    >
                      <img
                        src={Asset83}
                        alt="img"
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      />
                      Payment On Delivery
                    </button>
                  )}
                  {loading && (
                    <button
                      type="submit"
                      class="btn login_btn "
                      style={{ backgroundColor: "white" }}
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Please Wait...
                    </button>
                  )}
                  {/* <button
                    type="submit"
                    class="btn login_btn "
                    style={{ backgroundColor: "white" }}
                    onClick={() => cashOnDelivery()}
                  >
                    <img
                      src={Asset83}
                      alt="img"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />
                    Payment On Delivery
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
