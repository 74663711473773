import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import { motion } from "framer-motion";


function MyCoupens() {
  const [couponData, setCouponData] = useState("");
  const [Loading, setLoading] = useState(false);



  //Get Coupon Details
  const getCouponInfo = () => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = "https://chakkimart.in/admin/UserApi/getCoupons";
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setLoading(false);

        setCouponData(response.data.coupons);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getCouponInfo();
  }, []);

  return (
    <div>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />
          <div style={{ backgroundColor: "#f4ede4" }}>
            <div className="container p-3">
              {couponData &&
                couponData.map((item) => {
                  //   console.log(item);

                  return (
                    <>
                      <div
                        class="card mt-2 mb-2"
                        style={{
                          backgroundColor: "#F7F4F0",
                          border: "1px solid #66432D",
                          borderRadius: "10px",
                        }}
                        initial={{x:"100vw"}}
                        animate={{x:0}}
                        transition={{delay:0.2,duration:0.6}}
                      >
                        <div class="card-body">
                          <h5 class="card-title">
                            Coupon Code : {item.coupon_code}
                          </h5>

                          <p class="card-text">
                            Coupon Discount Amount:{" "}
                            {item.coupon_discount_amount}
                          </p>
                          <p class="card-text">
                            Coupon Discount Type: {item.coupon_discount_type}
                          </p>
                          <p class="card-text">
                            Coupon Start Date:{" "}
                            {item.coupon_start_date
                              .split("-")
                              .reverse()
                              .join("-")}
                          </p>
                          <p class="card-text">
                            Coupon End Date:{" "}
                            {item.coupon_end_date
                              .split("-")
                              .reverse()
                              .join("-")}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>

          <Footer />
        </>
      )}
    </div>
  );
}

export default MyCoupens;
