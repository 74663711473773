import React, { useState, useEffect } from 'react';
import Asset48 from '../images/foot_img.png';
import Asset47 from '../images/Asset 47.png';
import Asset45 from '../images/Asset 45.png';
import Asset46 from '../images/Asset 46.png';
import Asset41 from '../images/Asset 41.png';
import Asset42 from '../images/Asset 42.png';
import Asset43 from '../images/Asset 43.png';
import Asset44 from '../images/Asset 44.png';
import Asset49 from '../images/Asset 49.png';
import Asset1px from '../images/foot_logo.png';
import { Link } from 'react-router-dom';

function Footer() {
  const [footerData, setFooterData] = useState({});

  // Fetch API data when the component mounts
  useEffect(() => {
    fetch("https://chakkimart.in/admin/UserApi/homescreendata")
      .then((response) => response.json())
      .then((data) => {
        setFooterData(data.headerfooter_data[0]); // Set the footer data from API
      })
      .catch((error) => console.error("Error fetching footer data:", error));
  }, []);

  return (
    <div>
      <div className="footer_container1">
        <div className="container">
          <div className="row">
            {/* <img src={Asset1px} className="footer_logo" /> */}
            {/* <div className="tagline">Tagline goes here</div> */}
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex footer_heading_div" style={{ justifyContent: "center" }}>
                <Link to="#" className="footer_heading">Privacy Policy</Link>
                <div className="footer_vb"></div>
                <Link to="#" className="footer_heading">Terms And Conditions</Link>
                <div className="footer_vb"></div>
                <Link to="#" className="footer_heading">Contact</Link>
              </div>
            </div>
          </div>

          <div className="row mt-4">
         <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ gap: "20px", width: "100%" }}>
    
            {/* WhatsApp Number */}
            <div className="d-flex align-items-center">
            <img src={Asset46} className="footer_img" alt="Phone Icon" />
            <div style={{ marginLeft: "10px" }}>
                <div className="footer_txt">{footerData.wpnumber}</div>
            </div>
            </div>

            {/* Email */}
            <div className="d-flex align-items-center">
            <img src={Asset45} className="footer_img" alt="Email Icon" />
            <div style={{ marginLeft: "10px" }}>
                <a href={`mailto:${footerData.email}`} className="footer_txt">
                {footerData.email}
                </a>
            </div>
            </div>

            {/* Secondary Phone */}
            <div className="d-flex align-items-center">
            <img src={Asset47} className="footer_img" alt="Secondary Phone Icon" />
            <div style={{ marginLeft: "10px" }}>
                <div className="footer_txt">{footerData.mobile}</div>
            </div>
            </div>

            {/* Address */}
            <div className="d-flex align-items-center">
            <img src={Asset44} className="footer_img" alt="Location Icon" />
            <div className="footer_txt" style={{ marginLeft: "10px" }}>
                {footerData.address }
            </div>
            </div>

  </div>
</div>

            <div className="row" style={{ marginTop:"-26px" }}>
            <div className="col-md-12 footer_img_div">
                <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ gap: "20px" }}>
                {/* Social Icon 1 */}
                <a href={footerData.sociallink1} target="_blank" rel="noopener noreferrer">
                    <img src={Asset42} className="footer_img1" alt="Social Icon 1" />
                </a>

                {/* Social Icon 2 */}
                <a href={footerData.sociallink2} target="_blank" rel="noopener noreferrer">
                    <img src={Asset41} className="footer_img1" alt="Social Icon 2" />
                </a>

                {/* Social Icon 3 */}
                <a href={footerData.sociallink3} target="_blank" rel="noopener noreferrer">
                    <img src={Asset43} className="footer_img1" alt="Social Icon 3" />
                </a>

                {/* Social Icon 4 */}
                <a href={footerData.sociallink4} target="_blank" rel="noopener noreferrer">
                    <img src={Asset49} className="footer_img1" alt="Social Icon 4" />
                </a>
                </div>
            </div>
            </div>


          <div className="row footer_bottom">
            <div className="col-md-12">
              <div className="footer_bottom_txt">
                Copyright 2023 | All Rights Reserved | Design & Developed by
                <a href="https://www.peaceinfotech.com/" className='foot_linksss'> Peaceinfotech Services Pvt Ltd</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
