import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Components/User/Home/Home";
import UserLogin from "./Components/User/Login/UserLogin";
import { ToastContainer,Slide,Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from "./Components/User/Profile/Profile";
import CategoriesPage from "./Components/User/CategoriesPage/CategoriesPage";
import MyCart from "./Components/User/MyCart/MyCart";
import MyAddress from "./Components/User/Home/Address/MyAddress";
import EditAddressModal from "./Components/User/Home/Address/EditAddressModal";
import AddressAddToCart from "./Components/User/Home/Address/AddressAddToCart";
import MyCoupens from "./Components/User/MyCuopens/MyCoupens";
import Notification from "./Components/User/MyNotification/Notification";
import Delivery from "./Components/User/DeliveryPage/Delivery";
import OrderPage from "./Components/User/DeliveryPage/OrderPage";
import PopularCategoryPage from "./Components/User/CategoriesPage/PopularCategoryPage";
import MyFavorites from "./Components/User/MyFavorites/MyFavorites";
import OrderCancelPage from "./Components/User/DeliveryPage/OrderCancelByUser";
import {lazy,Suspense} from 'react'
// import Loader from "./Components/User/Home/Loader/Loader";
import Aboutus from "./Components/User/About/Aboutus";
import Contactus from "./Components/User/Contactus/Contactus";
import Terms from "./Components/User/TermsCondition/Terms";
import PrivacyPolicy from "./Components/User/PrivacyPolicy/PrivacyPolicy";
import Thanks from "./Components/User/ThanksPage/Thanks";



// const Home = lazy(() => import('./Components/User/Home/Home'));
// const MyCoupens = lazy(() => import('./Components/User/MyCuopens/MyCoupens'));
// const MyCart = lazy(() => import('./Components/User/MyCart/MyCart'));
// const AddressAddToCart = lazy(() => import('./Components/User/Home/Address/AddressAddToCart'));
// const OrderPage =lazy(() => import('./Components/User/DeliveryPage/OrderPage'));
// const OrderCancelPage = lazy(() => import('./Components/User/DeliveryPage/OrderCancelByUser'));
// const Notification = lazy(() => import('./Components/User/MyNotification/Notification'));
// const Profile = lazy(() => import('./Components/User/Profile/Profile'));
// const MyAddress = lazy(() => import('./Components/User/Home/Address/MyAddress'));
// const EditAddressModal = lazy(() => import('./Components/User/Home/Address/EditAddressModal'));
// const CategoriesPage = lazy(() => import('./Components/User/CategoriesPage/CategoriesPage'));
// const PopularCategoryPage = lazy(() => import('./Components/User/CategoriesPage/PopularCategoryPage'));
// const MyFavorites = lazy(() => import('./Components/User/MyFavorites/MyFavorites'));



function App() {
  return (
    <div>
      {/* <Router>
      <ToastContainer transition={Flip} />
        <Routes>
        <Route exact path="/" element={<UserLogin/>}/>
        <Route exact path="/home/:user_id/mycart" element={<MyCart />} />
        <Route exact path="/home/:user_id/mycart/add_address" element={<AddressAddToCart />} />


          <Route exact path="/home" element={<Home />} />
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/contactus" element={<Contactus />} />
          <Route exact path="/terms_conditions" element={<Terms />} />
          <Route exact path="/privacy_policy" element={<PrivacyPolicy/>} />




          <Route exact path="/home/coupons" element={<MyCoupens/>} />
          <Route exact path="/home/my_orders" element={<OrderPage/>} />
          <Route exact path="/home/my_orders/cancelled_order" element={<OrderCancelPage/>} />



          <Route exact path="/home/notification" element={<Notification />} />
          <Route exact path="/home/myfavorites" element={<MyFavorites />} />


          <Route exact path="/home/profile/:user_id" element={<Profile/>} />
          <Route exact path="/home/address/:user_id" element={<MyAddress/>} />
          <Route exact path="/home/address/edit/:address_id" element={<EditAddressModal/>} />


          <Route exact path="/home/category/:category_id/all_items" element={<CategoriesPage/>} />
          <Route exact path="/home/category/:category_id/all_items/popular_items" element={<PopularCategoryPage/>} />



        </Routes>
      </Router> */}

<Router>
      <ToastContainer transition={Flip} />
        <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/home/:user_id/mycart" element={<MyCart />} />
        <Route exact path="/home/:user_id/mycart/add_address" element={<AddressAddToCart />} />


          <Route exact path="/login" element={<UserLogin />} />
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/contactus" element={<Contactus />} />
          <Route exact path="/terms_conditions" element={<Terms />} />
          <Route exact path="/privacy_policy" element={<PrivacyPolicy/>} />




          <Route exact path="/home/coupons" element={<MyCoupens/>} />
          <Route exact path="/home/my_orders" element={<OrderPage/>} />
          <Route exact path="/home/my_orders/cancelled_order" element={<OrderCancelPage/>} />



          <Route exact path="/home/notification" element={<Notification />} />
          <Route exact path="/home/myfavorites" element={<MyFavorites />} />


          <Route exact path="/home/profile/:user_id" element={<Profile/>} />
          <Route exact path="/home/address/:user_id" element={<MyAddress/>} />
          <Route exact path="/home/address/edit/:address_id" element={<EditAddressModal/>} />


          <Route exact path="/home/category/:category_id/all_items" element={<CategoriesPage/>} />
          <Route exact path="/home/category/:category_id/all_items/popular_items" element={<PopularCategoryPage/>} />

          <Route
            exact
            path="/home/:user_id/mycart/thanku"
            element={<Thanks />}
          />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
