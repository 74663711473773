import React from "react";
// import Gif from "../../images/gifloader.gif";

function Loader() {
  return (
    <div>
        {/* <div className="loader_container"> */}
            {/* <div className="loader"> */}
            {/* <img src={Gif} alt="gif" /> */}
            {/* </div> */}
        {/* </div> */}
    
    </div>
  );
}

export default Loader;
